<template>
  <div>
    <tab-bar-wrapper @newRow="showAddArInvoiceSheet"/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :query="query"
        :nested-query="nestedQuery"
        :filter="filters"
        :nested-filters="nestedFilters"
        :columns="columns"
        :nested-grid="true"
        :nested-columns="nestedColumns"
        :nested-context-menu-items="nestedContextMenuItems"
        :componentName="$options.name"
        @ready="gridReady"
        @nestedReady="nestedReady"
        @cell-key-down="gridKbEventSender"
      />
    </div>
<!--    <action-bar-wrapper-->
<!--      :commentList="commentList"-->
<!--      @saveComments="saveComments"-->
<!--    />-->
    <v-dialog
      v-model="dialog.show"
      lazy
      persistent
      max-width="700px"
    >
      <component v-bind="dialog.props" :is="dialog.component" @close="resetDialog" @refetch="refetch"/>
    </v-dialog>
    <v-bottom-sheet
      v-model="sheet"
      lazy
      persistent
    >
      <new-invoice v-if="sheet" type="AR" @close="sheet = false" @success="refresh"/>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import { COLUMNS__ACCOUNTING_AR, COLUMNS__ACCOUNTING_AR_NESTED } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__ACCOUNTING_AR, CONTEXT_MENU__ACCOUNTING_AR_NESTED } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__ACCOUNTING_AR, KB_SHORTCUT__ACCOUNTING_AR_NESTED } from '@/lib/agGridKbShortCuts'
import {
  GET_ACCOUNTING_AR_INVOICES__FULL,
  GET_INVOICE_PAYMENTS_FOR_AR_INVOICE__FULL, VoidInvoice
} from '@/api/graphql/Constants/Accounting'
import NewInvoice from '@/components/forms/NewInvoice'
import AddPaymentToInvoice from '@/components/dialogs/AddPaymentToInvoice'
import VoidInvoices from '@/components/dialogs/VoidInvoices'

export default {
  name: 'Receivable',
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'grid-wrapper': GridWrapper,
    'new-invoice': NewInvoice,
    'add-payment-to-invoice': AddPaymentToInvoice,
    'void-invoices': VoidInvoices
  },
  data () {
    return {
      query: GET_ACCOUNTING_AR_INVOICES__FULL,
      nestedQuery: GET_INVOICE_PAYMENTS_FOR_AR_INVOICE__FULL,
      filters: [],
      nestedFilters: (id) => [{ key: 'invoice__id', value: id }],

      sheet: false,
      dialog: { show: false, component: '', props: {} },

      contextMenuItems: (params) => CONTEXT_MENU__ACCOUNTING_AR(params, this.contextMenuCallback),
      nestedContextMenuItems: params => CONTEXT_MENU__ACCOUNTING_AR_NESTED(params, this.contextMenuCallback),

      gridApi: null,
      nestedGridApi: null,
      gridOptions: null,

      columns: COLUMNS__ACCOUNTING_AR,
      nestedColumns: COLUMNS__ACCOUNTING_AR_NESTED,

      selectedInvoiceId: null,
      invoicesToVoid: []
    }
  },
  methods: {
    contextMenuCallback ({ functionToRun, params, data, dataKey }) {
      if (dataKey) this[dataKey] = data
      if (functionToRun) this[functionToRun](params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__ACCOUNTING_AR(params, this.contextMenuCallback), KB_SHORTCUT__ACCOUNTING_AR_NESTED(params, this.contextMenuCallback))
    },

    resetDialog () {
      this.dialog.show = false
      this.dialog.component = ''
      this.dialog.props = {}
    },

    gridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      this.gridOptions = params
    },

    nestedReady (params) {
      this.nestedGridApi = params.api
      this.nestedColumnApi = params.columnApi
    },

    refetch () {
      this.nestedGridApi.purgeServerSideCache()
    },

    refresh () {
      this.$store.dispatch('data/changeRefresh', { bool: true })
    },

    /**
     * Dispatch action to start the process of creating an ar invoice
     */
    showAddArInvoiceSheet () {
      this.sheet = true
    },

    showAddPaymentToInvoiceDialog ({ params }) {
      const id = params.id
      Vue.set(this.dialog, 'props', { invoiceId: id.toString(), invoiceType: 'AR' })
      this.dialog.component = 'add-payment-to-invoice'
      this.dialog.show = true
    },

    prepareToVoidArInvoices ({ params }) {
      const invoices = []
      for (const node of params.api.getSelectedNodes()) {
        invoices.push(node.data)
      }
      this.dialog.show = true
      this.dialog.component = 'void-invoices'
      Vue.set(this.dialog, 'props', { invoices: invoices, type: 'AR' })
    }
  }
}
</script>
